<template>
  <div class="filter-dropdown">
    <b-dropdown :variant="variant">
      <template #button-content>
        <SettingsIcon class="mr-2" /> {{ selected.name }}
      </template>
      <b-dropdown-item v-for="(filter, i) in filters" :key="i" @click="setFilter(filter)">
        {{ filter.name }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>
<script>
import SettingsIcon from "./svg/SettingsIcon.vue";
export default {
  name: "FilterDropdown",
  components: {
    SettingsIcon,
  },
  props: {
    filters: {
      type: Array,
      required: true,
    },
    variant: {
      type: String,
      default: "primary",
    },
  },
  data() {
    return {
      selected: this.filters[0],
    };
  },
  methods: {
    setFilter(filter) {
      this.selected = filter;
      this.$emit("filtered", filter.value);
    },
  },
};
</script>
<style lang="scss" scoped></style>
