<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.25518 4.12539C9.50518 4.12539 9.70518 3.92539 9.70518 3.67539V1.35039C9.70518 1.10039 9.50518 0.900391 9.25518 0.900391C9.00518 0.900391 8.80518 1.10039 8.80518 1.35039V3.67539C8.80518 3.92039 9.01018 4.12539 9.25518 4.12539Z"
      fill="white"
    />
    <path
      d="M5.9998 7.875C5.7498 7.875 5.5498 8.075 5.5498 8.325V10.65C5.5498 10.9 5.7498 11.1 5.9998 11.1C6.2498 11.1 6.4498 10.9 6.4498 10.65V8.325C6.4498 8.08 6.2498 7.875 5.9998 7.875Z"
      fill="white"
    />
    <path
      d="M2.74492 4.12539C2.99492 4.12539 3.19492 3.92539 3.19492 3.67539V1.35039C3.19492 1.10039 2.99492 0.900391 2.74492 0.900391C2.49492 0.900391 2.29492 1.10039 2.29492 1.35039V3.67539C2.29492 3.92039 2.49492 4.12539 2.74492 4.12539Z"
      fill="white"
    />
    <path
      d="M3.67523 5.08496H1.81523C1.56523 5.08496 1.36523 5.28496 1.36523 5.53496C1.36523 5.78496 1.56523 5.98496 1.81523 5.98496H2.29523V10.65C2.29523 10.9 2.49523 11.1 2.74523 11.1C2.99523 11.1 3.19523 10.9 3.19523 10.65V5.98496H3.67523C3.92523 5.98496 4.12523 5.78496 4.12523 5.53496C4.12523 5.28496 3.92023 5.08496 3.67523 5.08496Z"
      fill="white"
    />
    <path
      d="M10.185 5.08496H8.325C8.075 5.08496 7.875 5.28496 7.875 5.53496C7.875 5.78496 8.075 5.98496 8.325 5.98496H8.805V10.65C8.805 10.9 9.005 11.1 9.255 11.1C9.505 11.1 9.705 10.9 9.705 10.65V5.98496H10.185C10.435 5.98496 10.635 5.78496 10.635 5.53496C10.635 5.28496 10.435 5.08496 10.185 5.08496Z"
      fill="white"
    />
    <path
      d="M6.93012 6.01539H6.45012V1.35039C6.45012 1.10039 6.25012 0.900391 6.00012 0.900391C5.75012 0.900391 5.55012 1.10039 5.55012 1.35039V6.01539H5.07012C4.82012 6.01539 4.62012 6.21539 4.62012 6.46539C4.62012 6.71539 4.82012 6.91539 5.07012 6.91539H6.93012C7.18012 6.91539 7.38012 6.71539 7.38012 6.46539C7.38012 6.21539 7.18012 6.01539 6.93012 6.01539Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "SettingsIcon",
};
</script>
